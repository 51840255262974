import DocumentApis from '@/services/apis/document'

const document = new DocumentApis()
export default {
  state: {
    documentInfo: {
      general: {
        pending: '',
        success: '',
        rejected: '',
        status: '',
        identity: [],
      },
      residency: {
        pending: '',
        success: '',
        rejected: '',
        status: '',
      },
      bankAccounts: {
        pending: '',
        success: [],
        rejected: '',
        status: '',
      },
      legalCompanies: {
        pending: '',
        success: '',
        rejected: '',
        status: '',
        companyType: [],
      },
    },
  },
  getters: {
    allGeneralDocuments: state => state.documentInfo.general,
    allResidencyDocuments: state => state.documentInfo.residency,
    allBankAccountsDocuments: state => state.documentInfo.bankAccounts,
    allLegalCompaniesDocuments: state => state.documentInfo.legalCompanies,
  },
  mutations: {
    // SET_DOCUMENT_INFO(state, payload) {
    //   if (payload.length !== 0) {
    //     state.documentInfo[payload].pending = ''
    //     state.documentInfo[payload].success = ''
    //     state.documentInfo[payload].rejected = ''
    //     state.documentInfo[payload].status = 'full'
    //     Object.entries(payload).forEach(([key, value]) => {
    //       if (value.check_status === 'pending') {
    //         state.documentInfo.general.pending = value
    //       } else if (value.check_status === 'success') {
    //         state.documentInfo.general.success = value
    //       } else if (value.check_status === 'rejected') {
    //         state.documentInfo.general.rejected = value
    //       }
    //     })
    //   } else {
    //     state.documentInfo.general.status = 'empty'
    //   }
    // },
    SET_GENERAL_INFO(state, general) {
      if (general.length !== 0) {
        state.documentInfo.general.pending = ''
        state.documentInfo.general.success = ''
        state.documentInfo.general.rejected = ''
        state.documentInfo.general.status = 'full'
        Object.entries(general).forEach(([key, value]) => {
          if (value.check_status === 'pending') {
            state.documentInfo.general.pending = value
          } else if (value.check_status === 'success') {
            state.documentInfo.general.success = value
          } else if (value.check_status === 'rejected') {
            state.documentInfo.general.rejected = value
          }
        })
      } else {
        state.documentInfo.general.status = 'empty'
      }
    },
    SET_RESIDENCY(state, residency) {
      if (residency.length !== 0) {
        state.documentInfo.residency.pending = ''
        state.documentInfo.residency.success = ''
        state.documentInfo.residency.rejected = ''
        state.documentInfo.residency.status = 'full'
        Object.entries(residency).forEach(([key, value]) => {
          if (value.check_status === 'pending') {
            state.documentInfo.residency.pending = value
          } else if (value.check_status === 'success') {
            state.documentInfo.residency.success = value
          } else if (value.check_status === 'rejected') {
            state.documentInfo.residency.rejected = value
          }
        })
      } else {
        state.documentInfo.residency.status = 'empty'
      }
    },
    SET_BANK_ACCOUNT(state, bankAccounts) {
      if (bankAccounts.length !== 0) {
        state.documentInfo.bankAccounts.pending = ''
        state.documentInfo.bankAccounts.success = ''
        state.documentInfo.bankAccounts.rejected = ''
        state.documentInfo.bankAccounts.status = 'full'
        const successArray = []
        Object.entries(bankAccounts).forEach(([key, value]) => {
          if (value.check_status === 'pending') {
            state.documentInfo.bankAccounts.pending = value
          } if (value.check_status === 'success') {
            successArray.push(value)
          } if (value.check_status === 'rejected') {
            state.documentInfo.bankAccounts.rejected = value
          }
        })
        state.documentInfo.bankAccounts.success = successArray
      } else {
        state.documentInfo.bankAccounts.status = 'empty'
      }
    },
    SET_LEGAL_COMPANIES(state, legalCompanies) {
      if (legalCompanies.length !== 0) {
        state.documentInfo.legalCompanies.pending = ''
        state.documentInfo.legalCompanies.success = ''
        state.documentInfo.legalCompanies.rejected = ''
        state.documentInfo.legalCompanies.status = 'full'
        Object.entries(legalCompanies).forEach(([key, value]) => {
          if (value.check_status === 'pending') {
            state.documentInfo.legalCompanies.pending = value
          } if (value.check_status === 'success') {
            state.documentInfo.legalCompanies.success = value
          } if (value.check_status === 'rejected') {
            state.documentInfo.legalCompanies.rejected = value
          }
        })
      } else {
        state.documentInfo.legalCompanies.status = 'empty'
      }
    },
    SET_COMPANY_TYPE(state, companyType) {
      state.documentInfo.legalCompanies.companyType = companyType
    },
    SET_IDENTITY(state, identity) {
      state.documentInfo.general.identity = identity
    },
  },
  actions: {
    async saveDocumentGeneral({ commit }, data) {
      const response = await document.saveDocumentGeneral(data)
      if (response.status === 201) {
        commit('SET_GENERAL_INFO', response.data.results.general)
      }
      return response
    },
    async saveDocumentResidency({ commit }, data) {
      const response = await document.saveDocumentResidency(data)
      if (response.status === 201) {
        commit('SET_RESIDENCY', response.data.results.residency)
      }
      return response
    },
    async saveDocumentBankAccounts({ commit }, data) {
      const response = await document.saveDocumentBankAccounts(data)
      if (response.status === 201) {
        commit('SET_BANK_ACCOUNT', response.data.results.bankAccounts)
      }
      return response
    },
    async saveDocumentLegal({ commit }, data) {
      const response = document.saveDocumentLegal(data)
      if (response.status === 201) {
        commit('SET_LEGAL_COMPANIES', response.data.results.legalCompanies)
      }
      return response
    },
    async getDocumentInfo({ commit }) {
      const response = await document.getDocumentInfo()
      if (response.status === 200) {
        commit('SET_CITIZENSHIP', response.data.citizenship)
        commit('SET_MAX_UPLOAD_SIZE', response.data.max_upload_size)
        commit('SET_GENERAL_INFO', response.data.results.general)
        commit('SET_RESIDENCY', response.data.results.residency)
        commit('SET_BANK_ACCOUNT', response.data.results.bankAccounts)
        commit('SET_LEGAL_COMPANIES', response.data.results.legalCompanies)
        commit('SET_COMPANY_TYPE', response.data.results.companyType)
        commit('SET_IDENTITY', response.data.results.identity)
      }
      return response
    },
  },
}
