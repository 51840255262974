import forex from '@/modules/forex'
import crypto from '@/modules/crypto'
import ib from '@/modules/IB'
import copytrade from '@/modules/copytrade'
import bonus from '@/modules/bonus'

const activeModules = process.env.VUE_APP_ACTIVE_MODULES.split(',')
const modules = {
  ...(activeModules.includes('FOREX') && { forex }),
  ...(activeModules.includes('CRYPTO') && { crypto }),
  ...(activeModules.includes('IB') && { ib }),
  ...(activeModules.includes('COPYTRADE') && { copytrade }),
  ...(activeModules.includes('BONUS') && { bonus }),
}

export default modules
