export default [
  {
    path: '/:lang/forex/accounts',
    name: 'forexModule-accounts',
    component: () => import('@/modules/forex/views/metatrader/accounts.vue'),
    meta: {
      navActiveLink: 'forexModule-accounts',
      pageTitle: 'forex.metatrader-accounts',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Metatrader',
          active: true,
          to: {
            name: 'metatrader_accounts',
          },
        },
      ],
    },
  },

  {
    path: '/:lang/forex/trade-history/:platform/:id',
    name: 'forexModule-trade-History',
    component: () => import('@/modules/forex/views/metatrader/tradeHistory.vue'),
    meta: {
      pageTitle: 'forex.metatrader-trade-history',
      navActiveLink: 'forexModule-accounts',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Metatrader',
          active: true,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/d-w-history/:platform/:id',
    name: 'forexModule-DW-History',
    component: () => import('@/modules/forex/views/metatrader/transferHistory.vue'),
    meta: {
      pageTitle: 'forex.metatrader-transfer-history',
      navActiveLink: 'forexModule-accounts',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Metatrader',
          active: true,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/metatrader/create-account',
    name: 'forexModule-metatrader-create-account',
    component: () => import('@/modules/forex/views/metatrader/createAccount.vue'),
    meta: {
      pageTitle: 'forex.metatrader-create-account',
      navActiveLink: 'forexModule-accounts',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Metatrader',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/account-setting/:platform/:id',
    name: 'forexModule-account-setting',
    component: () => import('@/modules/forex/views/metatrader/setting.vue'),
    meta: {
      pageTitle: 'forex.metatrader-account-settings',
      navActiveLink: 'forexModule-accounts',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Metatrader',
          active: true,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/transfer-account-balance/:platform?/:id',
    name: 'forexModule-transfer-account-balance',
    component: () => import('@/modules/forex/views/metatrader/transfer.vue'),
    meta: {
      pageTitle: 'forex.metatrader-transfers',
      navActiveLink: 'forexModule-accounts',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Metatrader',
          active: true,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    // this
    path: '/:lang/forex/deposit/bank/:id?',
    name: 'forexModule-bank-deposit',
    component: () => import('@/modules/forex/views/metatrader/bankDeposit.vue'),
    meta: {
      navActiveLink: 'forexModule-deposit',
      route: 'forexModule-bank-deposit',
      pageTitle: 'forex.metatrader-bank-deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/deposit/online/status',
    name: 'forexModule-online-status',
    component: () => import('@/modules/forex/views/metatrader/onlineDepositStatus.vue'),
    meta: {
      navActiveLink: 'forexModule-deposit',
      route: 'forexModule-online-status',
      pageTitle: 'forexModule_online_status',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
          to: {
            name: 'deposit',
          },

        },
      ],
    },
  },
  {
    path: '/:lang/forex/deposit/online/:id?',
    name: 'forexModule-online-deposit',
    component: () => import('@/modules/forex/views/metatrader/onlineDeposit.vue'),
    meta: {
      navActiveLink: 'forexModule-deposit',
      route: 'forexModule-online-deposit',
      pageTitle: 'forex.metatrader-online-deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/deposit/crypto/:id?',
    name: 'forexModule-crypto-deposit',
    component: () => import('@/modules/forex/views/metatrader/cryptoDeposit.vue'),
    meta: {
      navActiveLink: 'forexModule-deposit',
      route: 'forexModule-crypto-deposit',
      pageTitle: 'forex.metatrader-crypto-deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/deposit/:id?',
    name: 'forexModule-deposit',
    component: () => import('@/modules/forex/views/metatrader/deposit.vue'),
    meta: {
      navActiveLink: 'forexModule-deposit',
      route: 'forexModule-deposit',
      pageTitle: 'forex.metatrader-deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/withdrawal/bank/:id?',
    name: 'forexModule-bank-withdrawal',
    component: () => import('@/modules/forex/views/metatrader/bankWithdrawal.vue'),
    meta: {
      navActiveLink: 'forexModule-withdrawal',
      route: 'forexModule-bank-withdrawal',
      pageTitle: 'forex.metatrader-bank-withdrawal',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'withdrawal',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/withdrawal/crypto/:id?',
    name: 'forexModule-crypto-withdrawal',
    component: () => import('@/modules/forex/views/metatrader/cryptoWithdrawal.vue'),
    meta: {
      navActiveLink: 'forexModule-withdrawal',
      route: 'forexModule-crypto-withdrawal',
      pageTitle: 'forex.metatrader-crypto-withdrawal',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'withdrawal',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/withdrawal/:id?',
    name: 'forexModule-withdrawal',
    component: () => import('@/modules/forex/views/metatrader/withdrawal.vue'),
    meta: {
      navActiveLink: 'forexModule-withdrawal',
      route: 'forexModule-withdrawal',
      pageTitle: 'forex.metatrader-withdrawal',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'withdrawal',
          active: false,
          to: {
            name: 'withdrawal',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/account/change-password/:platform?/:id',
    name: 'forexModuleaccount-change-password',
    component: () => import('@/modules/forex/views/metatrader/changePassword.vue'),
    meta: {
      navActiveLink: 'forexModule-accounts',
      route: 'metatrader_change_password',
      pageTitle: 'forex.metatrader-change-password',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'forex.change-password',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/create/demo-account/:platform?/:group?/:refID?',
    name: 'forexModule-create-demo-account',
    component: () => import('@/modules/forex/views/metatrader/demoAccount.vue'),
    meta: {
      navActiveLink: 'forexModule-accounts',
      route: 'create-demo-account',
      pageTitle: 'create-demo-account ',
      layout: 'full',
      middleware: 'guest',
      breadcrumb: [
        {
          text: 'create-demo-account',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    // this
    path: '/:lang/forex/tools-list',
    name: 'forexModule-tools-list',
    component: () => import('@/modules/forex/views/metatrader/toolsList.vue'),
    meta: {
      navActiveLink: 'forexModule-tools-list',
      route: 'forexModule-tools-list',
      pageTitle: 'metatrader_tools_list',
      middleware: 'guest',
      breadcrumb: [
        {
          text: 'metatrader_tools_list',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/forex/tool/:name',
    name: 'forexModule-tool',
    component: () => import('@/modules/forex/views/metatrader/tool.vue'),
    meta: {
      navActiveLink: 'forexModule-tools-list',
      route: 'forexModule-tool',
      pageTitle: 'metatrader_tool',
      middleware: 'guest',
      breadcrumb: [
        {
          text: 'metatrader_tool',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },
]
